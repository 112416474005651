import { getAppSettings } from '../../config';

const { supportPhoneNumber } = getAppSettings();

export const EPISODE_OR_OWNER_INACTIVE_WARNING_MESSAGE = `To re-connect with Concert services, call us at ${supportPhoneNumber}.`;
export const SECURE_MESSAGES_UNAVAILABE = `Secure Messages are currently unavailable. Please contact your Collaborative Care Clinician or call ${supportPhoneNumber} for assistance.`;
export const MESSAGE_NOT_SAVED = `Your message was not saved. Please contact your Collaborative Care Clinician or call ${supportPhoneNumber} for assistance.`;

export const ERROR_CODES = {
    PATIENT_CANT_RECEIVE_COMMUNICATIONS: 'PATIENT_CANT_RECEIVE_COMMUNICATIONS',
    INACTIVE_EPISODE: 'INACTIVE_EPISODE',
    INACTIVE_EPISODE_OWNER: 'INACTIVE_EPISODE_OWNER',
};
