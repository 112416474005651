import { NotificationFunctionsType } from 'concert-ui-library';
import { ConcertGraphqlValidationError } from './services/graphql/generated';

export const isConcertGraphqlValidationError = (
    responseObject: any,
): responseObject is ConcertGraphqlValidationError => {
    return (
        responseObject &&
        '__typename' in responseObject &&
        // eslint-disable-next-line no-underscore-dangle
        responseObject.__typename === 'ConcertGraphqlValidationError'
    );
};

export const concertGraphqlErrorHandler = (
    error: ConcertGraphqlValidationError,
    notificationContext: NotificationFunctionsType,
    errorMap: Map<string, string>,
    defaultErrorMsg: string,
) => {
    const { errorCode } = error;
    const mappedErrorCodeMessage = errorMap.get(errorCode);
    notificationContext?.showError(mappedErrorCodeMessage ?? defaultErrorMsg);
};
